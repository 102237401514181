<template>
    <div>
      <b-container class="mt-5 mb-5">
        <b-button :to="'/manage/events/' + $route.params.id + '/notifications/create'" variant="primary" class="float-right">
          Nieuw bericht
        </b-button>
        <h1 class="fs-4 font-weight-bold mb-4">Bezoekers informeren</h1>
        
        <b-alert variant="info" show>
            Je kunt berichten aanmaken om je bezoekers eenvoudig per e-mail op de hoogte te stellen van eventuele wijzigingen.
        </b-alert>

        <b-card class="mb-3">
            <div v-if="notifications.length <= 0" class="text-center text-muted mt-4 mb-4">
                <font-awesome-icon icon="users" class="mb-2" size="2x" />
                <div class="text-dark fs-5">Geen berichten gevonden</div>
                <div>Je hebt nog geen berichten aangemaakt.</div>
            </div>
            <template v-else>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <td>Onderwerp</td>
                            <td>Verzonden</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(notification, key) in notifications" :key="key">
                            <td>{{ notification.subject }}</td>
                            <td>{{ notification.schedule_at|formatTextDateTime }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </b-card>
      </b-container>
    </div>
</template>
  
<script>
    export default {
        metaInfo: {
            title: 'Bezoekers informeren',
        },
        components: {},
        data() {
            return {
                notifications: []
            }
        },
        computed: {},
        methods: {
            getNotifications: function() {
                this.$axios.get('https://api.tixgo.nl/events/' + this.$route.params.id + '/notifications')
                .then( response => {
                    if (response.data) {
                        this.notifications = response.data;
                    }
                })
            }
        },
        created() {
            this.getNotifications();
        },
    }
</script>